import React from 'react';
import './App.css'; 

import graphPower from './graphpower.png';
import uniswapPower from './uniswappower.png';
import dexPower from './dexpower.png';
import toolsPower from './toolspower.png';
import scanPower from './scanpower.png';
import linkPower from './linkpower.png';

const Footer = () => {
  return (
    <footer className="footer-container">
    <div className="footer-divider"></div> {/* Divider line */}
      <div className="powered-by">
        <p></p>
        <p>Powered by:</p>
        <div className="partner-logos">
          <a href="https://thegraph.com/" target="_blank" rel="noopener noreferrer">
            <img src={graphPower} alt="The Graph" />
          </a>
          <a href="https://dexscreener.com/" target="_blank" rel="noopener noreferrer">
            <img src={dexPower} alt="Dexscreener" />
          </a>
          <a href="https://uniswap.org/" target="_blank" rel="noopener noreferrer">
            <img src={uniswapPower} alt="Uniswap Protocol" />
          </a>
          <a href="https://etherscan.io/" target="_blank" rel="noopener noreferrer">
            <img src={scanPower} alt="Etherscan" />
          </a>
          <a href="https://www.dextools.io/" target="_blank" rel="noopener noreferrer">
            <img src={toolsPower} alt="Dextools" />
          </a>
          <a href="https://chain.link/" target="_blank" rel="noopener noreferrer">
            <img src={linkPower} alt="Chainlink" />
          </a>
        </div>
      </div>
      <div className="copyright">
  <p>Copyright © 2024, <a href="https://www.coinblow.com/" className="footer-link">CoinBlow</a>.</p>
</div>
    </footer>
  );
};

export default Footer;
