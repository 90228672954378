import React from 'react';
import './App.css';

const ErrorScreen = ({ errorType }) => {
    const isNetworkError = errorType === "network";
    const errorMessage = isNetworkError 
        ? "Your internet connection might be down. Please check your internet service and refresh the page." 
        : "The devs might be updating the website. Please check back later.";

    const errorTitle = isNetworkError ? "Connection Error" : "Server Error";

    const errorStyle = {
        fontFamily: '"Arial", sans-serif',
        textAlign: 'center',
        color: '#14c8a0',
    };

    const refreshPage = () => {
        window.location.reload();
    };

    return (
        <div className="loading-screen" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <div className="error-message" style={errorStyle}>
                <h2 style={{ fontSize: '1.5em', margin: '10px 0' }}>{errorTitle}</h2>
                <p style={{ fontSize: '1em' }}>{errorMessage}</p>
            </div>
            <div className="chart-button-container" style={{ marginTop: '20px', textAlign: 'center' }}>
                <button onClick={refreshPage} style={{ cursor: 'pointer' }}>
                    Refresh
                </button>
            </div>
        </div>
    );
};

export const NetworkErrorScreen = () => <ErrorScreen errorType="network" />;
export const ServerErrorScreen = () => <ErrorScreen errorType="server" />;
