import React, { useState } from 'react';
import './App.css';

const Navigation = () => {
  const [activeMenu, setActiveMenu] = useState('');

  return (
    <nav className="navbar">
      <a
        href="https://docs.coinblow.com"
        className="nav-link"
        target="_blank"
        rel="noopener noreferrer"
        onMouseEnter={() => setActiveMenu('listing')}
        onMouseLeave={() => setActiveMenu('')}
      >
        <span className="hover-effect"></span>
        DOCS
        <span className="hover-effect"></span>
      </a>
      <div
        className="nav-link"
        style={{ cursor: 'pointer' }}
        onMouseEnter={() => setActiveMenu('social')}
        onMouseLeave={() => setActiveMenu('')}
      >
        <span className="hover-effect"></span>
        SOCIAL
        <span className="hover-effect"></span>
        <div className={`submenu-content ${activeMenu === 'social' ? 'active' : ''}`}>
          <a
            href="https://twitter.com/CoinBlowDapp"
            className="nav-sublink"
            target="_blank"
            rel="noopener noreferrer"
          >
            CoinBlow Twitter
          </a>
          <a
            href="https://t.me/CoinBlowTrending"
            className="nav-sublink"
            target="_blank"
            rel="noopener noreferrer"
          >
            CoinBlow Bot
          </a>
          <a
            href="https://t.me/CoinBlowPortal"
            className="nav-sublink"
            target="_blank"
            rel="noopener noreferrer"
          >
            CoinBlow Coin Chat
          </a>
        </div>
      </div>

      <a
        href="https://t.me/CoinBlowTrending"
        className="nav-link"
        target="_blank"
        rel="noopener noreferrer"
        onMouseEnter={() => setActiveMenu('bot')}
        onMouseLeave={() => setActiveMenu('')}
      >
        <span className="hover-effect"></span>
        BOT
        <span className="hover-effect"></span>
      </a>

      <a
        href="https://request.coinblow.com"
        className="nav-link"
        target="_blank"
        rel="noopener noreferrer"
        onMouseEnter={() => setActiveMenu('listing')}
        onMouseLeave={() => setActiveMenu('')}
      >
        <span className="hover-effect"></span>
        LISTING
        <span className="hover-effect"></span>
      </a>
    </nav>
  );
};

export default Navigation;
