import React, { useState, useEffect } from 'react';
import presaleImage from './presale.png';
import stealthImage from './stealth.png';
import presaleOnImage from './presaleon.png';
import stealthOnImage from './stealthon.png';
import etherscanIcon from './etherscanicon.png';
import siteIcon from './siteicon.png';
import telegramIcon from './telegramicon.png';
import twitterIcon from './twittericon.png';
import buyIcon from './buyicon.png';
import './App.css';

const ITEMS_PER_PAGE = 50;
const JSON_URL = '/ethupcoming.json';

const Upcoming = () => {
    const [tokens, setTokens] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('Presale');
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        fetch(JSON_URL)
            .then(response => response.json())
            .then(data => setTokens(data.tokens))
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    const filteredTokens = tokens.filter(token => token.category === selectedCategory);
    const totalPages = Math.ceil(filteredTokens.length / ITEMS_PER_PAGE);
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const paginatedTokens = filteredTokens.slice(startIndex, startIndex + ITEMS_PER_PAGE);

    const changePage = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const renderPagination = () => {
        let pages = [];
        for (let i = 1; i <= totalPages; i++) {
            pages.push(
                <div key={i} className={`pagination-item ${currentPage === i ? 'active' : ''}`}>
                    <button className="pagination-button" onClick={() => changePage(i)}>
                        {i}
                    </button>
                </div>
            );
        }
        return <div>{pages}</div>;
    };

    const renderTableRows = () => {
        return paginatedTokens.map((token, index) => (
            <tr key={index}>
                <td>{token.launchTime}</td>
                <td>{token.name}</td>
                <td>${token.symbol}</td>
                <td>
                    <button className="button-link" onClick={() => window.open(`https://etherscan.io/token/${token.contract}`, "_blank")}>
                        <img src={etherscanIcon} alt="Etherscan" /> Contract
                    </button>
                </td>
                <td>
                    <button className="button-link" onClick={() => window.open(token.website, "_blank")}>
                        <img src={siteIcon} alt="Website" /> Website
                    </button>
                </td>
                <td>
                    <button className="button-link" onClick={() => window.open(token.telegram, "_blank")}>
                        <img src={telegramIcon} alt="Telegram" /> Telegram
                    </button>
                </td>
                <td>
                    <button className="button-link" onClick={() => window.open(token.twitter, "_blank")}>
                        <img src={twitterIcon} alt="Twitter" /> Twitter
                    </button>
                </td>
                <td>
                    <button className="button-link" onClick={() => window.open(token.buy, "_blank")}>
                        <img src={buyIcon} alt="Buy" /> Buy Token
                    </button>
                </td>
            </tr>
        ));
    };
    
    return (
        <div className="pairs-table-container">
            <div className="slider-container">
                <button className={`slider-button ${selectedCategory === 'Presale' ? 'active' : ''}`} onClick={() => setSelectedCategory('Presale')}>
                    <img src={selectedCategory === 'Presale' ? presaleOnImage : presaleImage} alt="Presale" />
                </button>
                <button className={`slider-button ${selectedCategory === 'Stealth' ? 'active' : ''}`} onClick={() => setSelectedCategory('Stealth')}>
                    <img src={selectedCategory === 'Stealth' ? stealthOnImage : stealthImage} alt="Stealth" />
                </button>
            </div>
            <table className="pairs-table">
                <thead>
                    <tr>
                        <th>Launch Time:</th>
                        <th>Name:</th>
                        <th>Ticker Symbol:</th>
                        <th>Contract:</th>
                        <th>Website:</th>
                        <th>Telegram:</th>
                        <th>Twitter:</th>
                        <th>Buy:</th>
                    </tr>
                </thead>
                <tbody>
                    {renderTableRows()}
                </tbody>
            </table>
            {renderPagination()}
        </div>
    );
};

export default Upcoming;
