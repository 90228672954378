import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, NavLink, Routes, useLocation } from 'react-router-dom';
import useErrorHandling from './useErrorHandling';
import { NetworkErrorScreen, ServerErrorScreen } from './ErrorScreens';
import PairsTable from './PairsTable';
import Upcoming from './Upcoming';
import Trending from './Trending';
import Footer from './Footer';
import WarningNotice from './WarningNotice';
import { fetchDataFromServer } from './api';
import './App.css';
import Navigation from './Navigation'; 
import LoadingScreen from './LoadingScreen'; 
import liveCoins from './livecoins.png';
import liveCoinsOff from './livecoinsoff.png';
import upcomingCoins from './upcomingcoins.png';
import upcomingCoinsOff from './upcomingcoinsoff.png';
import flame from './flame.png';

const removeDuplicates = (tokens) => {
    return tokens.filter((token, index, self) =>
        index === self.findIndex((t) => (t.address === token.address))
    );
};

const MainContent = ({ tokens, selectedOption, onOptionChange }) => {
    const location = useLocation();
    const loadingText = location.pathname === '/upcoming' ? 'Updating New Launches:' : 'Discovering New Tokens:';
    return (
        <>
            <div className="loading-section">
                <img src="loadinggif.gif" alt="Loading" className="loading-gif" />
                <p>{loadingText}</p>
            </div>
            <Routes>
                <Route exact path="/" element={<PairsTable tokens={tokens} selectedOption={selectedOption} onOptionChange={onOptionChange} />} />
                <Route path="/upcoming" element={<Upcoming />} />
            </Routes>
        </>
    );
};

const App = () => {
    const [isLoading, setIsLoading] = useState(true);
    const isFirstLoad = useRef(true); 
    const [tokenData, setTokenData] = useState([]);
    const [selectedOption, setSelectedOption] = useState('Best Performers');
    const { hasNetworkError, hasServerError } = useErrorHandling(fetchDataFromServer);

    useEffect(() => {
        const updateTokenData = async () => {
            const serverData = await fetchDataFromServer();
            if (serverData && serverData.length > 0) {
                setTokenData(removeDuplicates(serverData));
            }
            if (isFirstLoad.current) {
                setTimeout(() => {
                    setIsLoading(false);
                    isFirstLoad.current = false; 
                }, 4000); 
            }
        };

        updateTokenData();
        const interval = setInterval(updateTokenData, 30000); 

        return () => {
            clearInterval(interval);
        };
    }, []);

    const handleOptionChange = (option) => {
        setSelectedOption(option);
    };

    const sortedTokens = () => {
        const currentTime = Date.now();
    
        switch (selectedOption) {
            case 'Best Performers':
                return [...tokenData]
                    .filter(token => 
                        token.marketCap && token.liquidity && 
                        token.marketCap >= 50000 && 
                        currentTime - new Date(token.pairCreatedAt).getTime() < 86400000 &&
                        !(token.marketCap > 1000000 && (token.txns24h.buys + token.txns24h.sells) < 400))
                    .sort((a, b) => b.marketCap - a.marketCap);
    
            case 'Newest Tokens':
                return [...tokenData]
                    .filter(token => 
                        token.marketCap && token.liquidity &&
                        token.marketCap <= 100000 && 
                        currentTime - new Date(token.pairCreatedAt).getTime() < 86400000 &&
                        !(token.marketCap > 1000000 && (token.txns24h.buys + token.txns24h.sells) < 400))
                    .sort((a, b) => b.pairCreatedAt - a.pairCreatedAt);
    
            case 'Buy Signals':
                return tokenData
                    .filter(token => 
                        token.priceChange1h > 20 && 
                        token.marketCap && token.liquidity &&
                        token.marketCap > 250000 && token.marketCap <= 15000000 && token.marketCap <= token.liquidity * 30 &&
                        currentTime - new Date(token.pairCreatedAt).getTime() < 86400000 &&
                        !(token.marketCap > 1000000 && (token.txns24h.buys + token.txns24h.sells) < 400));
    
            default:
                return tokenData
                    .filter(token => 
                        token.marketCap && token.liquidity &&
                        token.marketCap <= 15000000 && token.marketCap <= token.liquidity * 30 &&
                        currentTime - new Date(token.pairCreatedAt).getTime() < 86400000 &&
                        !(token.marketCap > 1000000 && (token.txns24h.buys + token.txns24h.sells) < 400));
        }
    };
            
    return (
        <Router>
            {isLoading && <LoadingScreen />}
            {hasNetworkError && <NetworkErrorScreen />}
            {hasServerError && <ServerErrorScreen />}
            {!hasNetworkError && !hasServerError && (
                <div className="App">
                    <Trending tokens={sortedTokens().slice(0, 10)} />
                    <WarningNotice />
                    <div className="content-container">
                        <h1>COINBLOW V1 (BASE) <img src={flame} alt="Flame" /> </h1>
                        <Navigation />
                        <div className="top-toggle-container">
                            <NavLink to="/" className={({ isActive }) => isActive ? "top-toggle top-toggle-active" : "top-toggle"}>
                                {({ isActive }) => <img src={isActive ? liveCoins : liveCoinsOff} alt="Live" />}
                            </NavLink>
                            <NavLink to="/upcoming" className={({ isActive }) => isActive ? "top-toggle top-toggle-active" : "top-toggle"}>
                                {({ isActive }) => <img src={isActive ? upcomingCoins : upcomingCoinsOff} alt="Upcoming" />}
                            </NavLink>
                        </div>
                        <MainContent tokens={sortedTokens()} selectedOption={selectedOption} onOptionChange={handleOptionChange} />
                    </div>
                    <Footer />
                </div>
            )}
        </Router>
    );
};

export default App;
