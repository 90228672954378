import { useState, useEffect } from 'react';

const useErrorHandling = (fetchDataFromServer) => {
    const [hasNetworkError, setHasNetworkError] = useState(!navigator.onLine);
    const [hasServerError, setHasServerError] = useState(false);

    useEffect(() => {
        // Function to handle data fetching
        const fetchData = async () => {
            try {
                await fetchDataFromServer();
                setHasNetworkError(false);
                setHasServerError(false);
            } catch (error) {
                // If the error is due to network issues
                if (!navigator.onLine) {
                    setHasNetworkError(true);
                } 
                // If the error is from the server (status 500 and above)
                else if (error.response && error.response.status >= 500) {
                    setHasServerError(true);
                }
            }
        };

        
        fetchData();

        
        const handleOnline = () => setHasNetworkError(false);

        
        const handleOffline = () => setHasNetworkError(true);

        
        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, [fetchDataFromServer]);

    return { hasNetworkError, hasServerError };
};

export default useErrorHandling;
