import React from 'react';
import trendingImage from './trendingimage.png';

const Trending = ({ tokens }) => {
  const formatCurrency = (amount) => {
    const numAmount = Number(amount);
    if (isNaN(numAmount)) return 'N/A';
    if (numAmount >= 1000000) return `$${(numAmount / 1000000).toFixed(1)}M`;
    if (numAmount >= 1000) return `$${(numAmount / 1000).toFixed(1)}K`;
    return `$${numAmount.toFixed(2)}`;
  };

  const timeSinceCreation = (timestamp) => {
    const creationDate = new Date(timestamp);
    const now = new Date();
    const difference = now - creationDate;
    const minutes = Math.floor(difference / 60000);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    if (days > 0) return `${days}D`;
    if (hours > 0) return `${hours}H, ${minutes % 60}m`;
    return `${minutes}m`;
  };

  return (
    <div className="trending">
      <div className="trending-text"><img src={trendingImage} alt="Trending Launches" /></div>
      <div className="trending-items">
        {tokens.map((token, index) => (
          <div key={token.address} className="trending-item">
            <span>#{index + 1} </span>
            <a href={`https://dexscreener.com/ethereum/${token.address}`} target="_blank" rel="noopener noreferrer">
              ${token.symbol}
            </a>
            <span> ({formatCurrency(token.marketCap)} | {timeSinceCreation(token.pairCreatedAt)} ago)</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Trending;
