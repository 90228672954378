import React, { useState, useEffect } from 'react';
import topLeft from './topleft.png';
import topRight from './topright.png';
import bottomLeft from './bottomleft.png';
import bottomRight from './bottomright.png';
import './App.css';

const LoadingScreen = () => {
    const [percentage, setPercentage] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setPercentage(oldPercentage => {
                const newPercentage = oldPercentage + 1;
                if (newPercentage === 100) {
                    clearInterval(interval); 
                }
                return newPercentage;
            });
        }, 40); 

        return () => clearInterval(interval); 
    }, []);

    return (
        <div className="loading-screen">
            <img src={topLeft} className="logo-part top-left" alt="top-left" />
            <img src={topRight} className="logo-part top-right" alt="top-right" />
            <img src={bottomLeft} className="logo-part bottom-left" alt="bottom-left" />
            <img src={bottomRight} className="logo-part bottom-right" alt="bottom-right" />
            <div className="loading-bar-container">
                <div className="loading-text">Loading:</div>
                <div className="loading-bar" style={{ width: `${percentage}%` }}>
                    <div className="loading-percentage">{percentage}%</div>
                </div>
            </div>
        </div>
    );
};

export default LoadingScreen;
