import React, { useState } from 'react';
import greenArrow from './greenarrow.png';
import redArrow from './redarrow.png';
import uniswapIcon from './uniswap.png';
import dextoolsIcon from './dextools.png';  
import dexscreenerIcon from './dexscreener.png';  
import performersImage from './performers.png';
import newtokensImage from './newtokens.png';
import buysignalsImage from './buysignals.png';
import signalarrowImage from './signalarrow.png';
import performersOnImage from './performerson.png';
import newTokensOnImage from './newtokenson.png';
import buySignalsOnImage from './buysignalson.png';
import etherscanIcon from './etherscanbutton.png';
import buyIcon from './buys.png';
import sellIcon from './sells.png';
import tokensnifferIcon from './tokensniffer.png';
import pinImage from './pin.png';


const ITEMS_PER_PAGE = 50;

const PairsTable = ({ tokens, selectedOption, onOptionChange }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(tokens.length / ITEMS_PER_PAGE);

    const changePage = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const paginatedTokens = tokens.slice(startIndex, startIndex + ITEMS_PER_PAGE);
    const dextoolsBaseUrl = "https://www.dextools.io/app/en/base/pair-explorer/";
    const dexscreenerBaseUrl = "https://dexscreener.com/base/";  
    const uniswapBaseUrl = "https://app.uniswap.org/#/swap?inputCurrency=";
    const etherscanBaseUrl = "https://basescan.org/token/";            
    const tokensnifferBaseUrl = "https://tokensniffer.com/token/base/";

    const formatCurrency = (amount) => {
        const numAmount = Number(amount);
        if (isNaN(numAmount)) return 'N/A';
        if (numAmount >= 1000000) return `$${(numAmount / 1000000).toFixed(1)}M`;
        if (numAmount >= 1000) return `$${(numAmount / 1000).toFixed(1)}K`;
        return `$${numAmount.toFixed(2)}`;
    };

    const formatLiquidity = (amount) => {
        const numAmount = Number(amount);
        if (isNaN(numAmount)) return 'N/A';
        if (numAmount < 100) return <span style={{ color: '#f23636' }}>RUGGED</span>;
        return formatCurrency(numAmount);
    };

    const formatPrice = (price) => {
        const numPrice = parseFloat(price);
        if (isNaN(numPrice)) return 'N/A';
    
        const match = /^0\.(0+)/.exec(price);
        if (match) {
            const leadingZeros = match[1].length; 
            const significantFigures = price.substring(match[0].length); 
    
            const displayedZeros = leadingZeros > 5 ? '00000' : match[1];
    
            return (
                <span className="price">
                    $0.<span className="zero">{displayedZeros}</span>{significantFigures}
                </span>
            );
        }
        
        return `$${numPrice.toFixed(2)}`;
    };

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

                                            
    const formatPercentage = (value, is24h = false) => {
        const formattedValue = `${value.toFixed(2)}%`;
        const colorStyle = value < 0 ? { color: '#f23636' } : { color: '#16deb7' };
        return is24h ? (
            <span style={colorStyle}>
                <span className="percentage-value">
                {value < 0 ? <img src={redArrow} alt="Red Arrow" className="red-arrow" /> : <img src={greenArrow} alt="Green Arrow" className="green-arrow" />}
                {formattedValue}
                </span>
            </span>
        ) : (
            <span style={colorStyle}>{formattedValue}</span>
        );
    };

    const timeSinceCreation = (timestamp) => {
        const creationDate = new Date(timestamp);
        const now = new Date();
        const difference = now - creationDate;
        const minutes = Math.floor(difference / 60000);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        if (days > 0) return `${days}D`;
        if (hours > 0) return `${hours}H, ${minutes % 60}m`;
        return `${minutes}m`;
    };

    const formatTxns = (buys, sells) => {
        return (
            <span>
                <img src={buyIcon} alt="Buy Icon" className="dividericon"/>
                <span className="buy-number">{buys}</span>
                <img src={sellIcon} alt="Sell Icon" className="dividericon"/>
                <span className="sell-number">{sells}</span>
            </span>
        );
    };
                
    const renderPagination = () => {
        if (tokens.length <= ITEMS_PER_PAGE) {
            return null;
        }
    
        let pages = [];
        for (let i = 1; i <= totalPages; i++) {
            pages.push(
                <div key={i} className={`pagination-item ${currentPage === i ? 'active' : ''}`}>
                    <button className="pagination-button" onClick={() => changePage(i)}>
                        {i}
                    </button>
                </div>
            );
        }
        return <div>{pages}</div>;
    };
    
    const renderBuySignal = (token) => {
        return (
            <div>
                <img src={signalarrowImage} alt="Signal Arrow" className="signal-arrow-animation" />
                <b>Coin BLOW 💣 Signal</b>: {truncateText(token.name, 30)} just surged {token.priceChange1h.toFixed(2)}% to {formatCurrency(token.marketCap)} market cap.
            </div>
        );
    };

    return (
        <div>
            <div className="slider-container">
            <button className={`slider-button ${selectedOption === 'Best Performers' ? 'active' : ''}`} onClick={() => onOptionChange('Best Performers')}>
    <div className="image-wrapper">
        <img src={selectedOption === 'Best Performers' ? performersOnImage : performersImage} alt="Top Performers" />
        {selectedOption === 'Best Performers' && <img src={pinImage} alt="Selected" className="pin-icon" />}
    </div>
</button>

<button className={`slider-button ${selectedOption === 'Newest Tokens' ? 'active' : ''}`} onClick={() => onOptionChange('Newest Tokens')}>
    <div className="image-wrapper">
        <img src={selectedOption === 'Newest Tokens' ? newTokensOnImage : newtokensImage} alt="New Tokens" />
        {selectedOption === 'Newest Tokens' && <img src={pinImage} alt="Selected" className="pin-icon" />}
    </div>
</button>

<button className={`slider-button ${selectedOption === 'Buy Signals' ? 'active' : ''}`} onClick={() => onOptionChange('Buy Signals')}>
    <div className="image-wrapper">
        <img src={selectedOption === 'Buy Signals' ? buySignalsOnImage : buysignalsImage} alt="Buy Signals" />
        {selectedOption === 'Buy Signals' && <img src={pinImage} alt="Selected" className="pin-icon" />}
    </div>
</button>
            </div>
    
            <div className="pairs-table-container">
                <table className="pairs-table">
                    <thead>
                        <tr>
                            {selectedOption === 'Buy Signals' ? <th>Surge Signal:</th> : null}
                            <th>Name/Ticker:</th>
                            <th>Creation Date:</th>
                            <th>Price (USD):</th>
                            <th>Liquidity:</th>
                            <th>5M:</th>
                            <th>1H:</th>
                            {selectedOption !== 'Buy Signals' && <th>6H:</th>}
                            <th>24H:</th>
                            <th>Market Cap:</th>
                            <th>BaseScan:</th>
                            <th>Chart:</th>
                            <th>Swap:</th>
                        </tr>
                    </thead>
                    <tbody>
                    {paginatedTokens.map((token, index) => (
    <React.Fragment key={index}>
        <tr>
            {selectedOption === 'Buy Signals' ? <td>{renderBuySignal(token)}</td> : null}
            <td>
                {truncateText(token.name, 30)}
                <div className="ticker-wrapper">
                    <a href={`${dexscreenerBaseUrl}${token.address}`} target="_blank" rel="noopener noreferrer">
                        (${token.symbol})
                    </a>
                </div>
            </td>
            <td>{token.pairCreatedAt ? timeSinceCreation(token.pairCreatedAt) : 'N/A'}</td>
            <td>{formatPrice(token.price)}</td>
            <td>{formatLiquidity(token.liquidity)}</td>
            <td>{formatPercentage(token.priceChange5m)}</td>
            <td>{formatPercentage(token.priceChange1h)}</td>
            {selectedOption !== 'Buy Signals' && <td>{formatPercentage(token.priceChange6h)}</td>}
            <td>{formatPercentage(token.priceChange24h, true)}  <div className="txn-data">
            {formatTxns(token.txns24h.buys, token.txns24h.sells)}
            </div></td>
            <td>{formatCurrency(token.marketCap)}</td>
            <td>
                <div className="chart-button-container">
                    <a href={`${etherscanBaseUrl}${token.address}`} target="_blank" rel="noopener noreferrer">
                        <button className="chart-button dexscreener-button">
                            <img src={etherscanIcon} alt="Etherscan" style={{ verticalAlign: 'middle' }} />
                        </button>
                    </a>
        <a href={`${tokensnifferBaseUrl}${token.address}`} target="_blank" rel="noopener noreferrer">
            <button className="chart-button">
                <img src={tokensnifferIcon} alt="TokenSniffer" />
            </button>
        </a>
    </div>

            </td>
            <td>
    <div className="chart-button-container">
        <a href={`${dexscreenerBaseUrl}${token.address}`} target="_blank" rel="noopener noreferrer">
            <button className="chart-button dexscreener-button">
                <img src={dexscreenerIcon} alt="Dexscreener" />
            </button>
        </a>
        <a href={`${dextoolsBaseUrl}${token.address}`} target="_blank" rel="noopener noreferrer">
            <button className="chart-button">
                <img src={dextoolsIcon} alt="Dextools" />
            </button>
        </a>
    </div>
</td>
          <td>
                <a href={`${uniswapBaseUrl}${token.address}`} target="_blank" rel="noopener noreferrer" style={{ display: 'block', width: '100%' }}>
                    <button style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <img src={uniswapIcon} alt="Uniswap" style={{ marginRight: '5px' }} />
                        Swap
                    </button>
                </a>
            </td>
        </tr>
    </React.Fragment>
))}
</tbody>
</table>
</div>
{renderPagination()}
</div>
);

};

export default PairsTable;
