import axios from 'axios';

const SERVER_ENDPOINT = '/superspacealiens/api/data';

export const fetchDataFromServer = async () => {
    try {
        const response = await axios.get(SERVER_ENDPOINT);
        return response.data.data;
    } catch (error) {
        return [];
    }
};
