import React from 'react';
import './App.css'; 

const WarningNotice = () => {
    return (
        <div className="warning-notice">
            <p>
                <strong>NOTE:</strong> We only list tokens launched on Base within the last 24H, and filter rugs based on multiple metrics. We have a Telegram coin community: <b><a href="https://t.me/CoinBlow" className="telegram-link">t.me/CoinBlow</a></b>. Detailed charts are available on DexScreener.
            </p>
        </div>
    );
};

export default WarningNotice;
